import * as React from 'react';

import { Container, Grid, GridItem, Card } from '@ao-internal/components-react';

import { ArticleGrid } from '../components/ArticleGrid';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import BackgroundVideo from 'react-background-video-player';
import { graphql } from 'gatsby';

export interface IndexProps {
	data: {
		wp: {
			generalSettings: {
				title: string;
				description: string;
			};
		};
		allWpPost: {
			pageInfo: {
				totalCount: number;
			};
			edges: {
				node: WPCard;
			}[];
		};
		wpPage: {
			featuredImage?: {
				node?: {
					sourceUrl: string;
				};
			};
		};
	};
}

export const pageQuery = graphql`
	query indexPageQuery {
		wp {
			generalSettings {
				title
				description
			}
		}
		allWpPost(limit: 3) {
			pageInfo {
				totalCount
			}
			edges {
				node {
					title
					excerpt
					isSticky
					date(formatString: "MMM DD, YYYY")
					slug
					author {
						node {
							name
							avatar {
								url
							}
						}
					}
					tags {
						nodes {
							name
							slug
						}
					}
				}
			}
		}
		wpPage(id: { eq: "cG9zdDo2" }) {
			featuredImage {
				node {
					sourceUrl
				}
			}
		}
	}
`;

// video files
const headerVideo = require('../images/parklands5b.mp4');
const videoThumb = require('../images/video-first-frame.png');

// other images
const headerImage = require('../images/ao-bolton3.jpg');
const teamsImage = require('../images/nwtt01.jpeg');

export const IndexPage: React.SFC<IndexProps> = ({
	data: { wp, allWpPost },
}) => (
	<React.Fragment>
		<Helmet
			bodyAttributes={{
				class: 'home',
			}}
		/>
		<Layout>
			<div
				className="blog-page__featured-image"
				style={{
					backgroundImage: `url(${headerImage})`,
				}}>
				<Container className="p-0 flex h-full sub-header">
					<Grid className="items-center">
						<GridItem key="1" className="h-full text-center items-center">
							<div
								className="video-background"
								style={{
									backgroundImage: `url(${videoThumb})`,
								}}>
								<div className="h-full relative">
									<BackgroundVideo
										src={headerVideo}
										containerWidth={100}
										containerHeight={100}
										autoPlay={true}
										muted={true}
										loop={true}
										playsinline={true}
										poster={videoThumb}
									/>
									<div className="video-content">
										<h1 className="text-display-lg text-white">
											{wp.generalSettings.description}
										</h1>
									</div>
								</div>
							</div>
						</GridItem>
					</Grid>
				</Container>
			</div>

			<Container className="index__section-one text-center">
				<Card>
					<Grid className="pb-6">
						<GridItem key="1">
							<h2 className="text-display">We bring AO's tomorrow to life</h2>
							<div className="text-base">
								AO Tech is where problems are solved using innovation and
								creativity.
								<br />
								We relentlessly strive every day to make tomorrow just a little
								bit better for our customers and our people.
							</div>
						</GridItem>
					</Grid>
					<Grid className="items-center pt-6">
						<GridItem key="1" span={{ def: 12, md: 4 }}>
							<h3 className="text-display-sm">Born in Bolton</h3>
							<div>the best digital team in the North</div>
						</GridItem>
						<GridItem
							key="2"
							span={{ def: 12, md: 4 }}
							className="my-6 md:my-0">
							<h3 className="text-display-sm">Come and meet us</h3>
							<div>
								<Link to="/events">view upcoming events</Link>
							</div>
						</GridItem>
						<GridItem key="3" span={{ def: 12, md: 4 }}>
							<h3 className="text-display-sm">200 members</h3>
							<div>
								across 30 <Link to="/teams">tech teams</Link>
							</div>
						</GridItem>
					</Grid>
				</Card>
			</Container>

			<h2 className="text-display text-center mt-8 mb-0">
				Latest from our blog
			</h2>

			<ArticleGrid articles={allWpPost.edges.map((edge) => edge.node)} />

			<Container className="px-0 pb-4">
				<div className="text-center blog-viewmore">
					{allWpPost.pageInfo.totalCount > 3 && (
						<Link to="/archive/" className="cta cta-secondary text-base">
							See all articles
						</Link>
					)}
				</div>
			</Container>

			<div
				className="blog-page__featured-image index__section-two-image mt-8"
				style={{
					backgroundImage: `url(${teamsImage})`,
				}}>
				<Container className="px-0 flex h-full">
					<Grid className="items-center">
						<GridItem key="1" className="h-full text-center items-center">
							{''}
						</GridItem>
					</Grid>
				</Container>
			</div>

			<Container className="index__section-two px-0 mb-8">
				<Grid>
					<GridItem key="1" span={{ def: 12, md: 6 }}>
						<Card className="text-center">
							<h2 className="text-display">Our Tech Stack</h2>
							<div className="text-body">
								<div className="mb-4">
									When it comes to technology - we're open, bold and choosy.
								</div>
								<div>
									Technology moves fast, but we move faster. Even with older
									languages and frameworks we find we’re only really limited by
									our imaginations. We don't straggle in adopting a new
									technology, but we're not magpies either - we don’t try
									everything that's new, just for the sake of it.
								</div>
								<div className="mt-4">
									<OutboundLink
										href="https://stackshare.io/ao-com/ao-com"
										target="_blank">
										View our stack on stackshare.io
									</OutboundLink>
								</div>
							</div>
						</Card>
					</GridItem>
					<GridItem key="2" span={{ def: 12, md: 6 }} className="mt-4 sm:mt-0">
						<Card className="text-center">
							<h2 className="text-display">Our Teams</h2>
							<div className="text-body">
								<div className="mb-4">
									We use what works best, we're informal and constantly
									evolving.
								</div>
								<div>
									We encourage lean/agile principles, but teams choose their own
									processes and technologies – they are closest to the problems
									they're solving and can make best decisions. We trust you
									enough to seek guidance and to make the right choices at the
									right time. If it doesn't work out, it's just a lesson learnt
									for next time.
								</div>
								<div className="mt-4">
									<Link to="/teams">Read more about our teams</Link>
								</div>
							</div>
						</Card>
					</GridItem>
				</Grid>
			</Container>
		</Layout>
	</React.Fragment>
);

export default IndexPage;
